<template>
  <svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>wild-bird-1</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M16.073,9.481a4.125,4.125,0,1,0-4.83-6.607,4.05,4.05,0,0,0-.75.956A3.91,3.91,0,0,1,7.216,5.849a6.747,6.747,0,1,0,4.925,11.642,6.683,6.683,0,0,0,2.1-4.67A3.866,3.866,0,0,1,16.073,9.481Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M17.6,8.02l.4.114S20.96,8.98,23.25,13"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M13.875,5.5a.375.375,0,0,1,.375.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M13.5,5.875a.375.375,0,0,1,.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M13.875,6.25a.375.375,0,0,1-.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14.25,5.875a.375.375,0,0,1-.375.375"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="12.483"
      y1="17.144"
      x2="15"
      y2="20.5"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="4.133"
      y1="18.426"
      x2="2.25"
      y2="21.25"
    />
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="14.25 22 15 20.5 18 19.75"
    />
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="0.75 22 2.25 21.25 4.5 22"
    />
  </svg>
</template>
